export const state_names = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const alpha_sort = (a, b) => {
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1
  }
  if (a.name.toUpperCase() < b.name.toUpperCase()) {
    return -1
  }
  return 0
}

export const format_append = (str, add, len) => {
  const mu = str.slice(0, len)
  return mu.concat(add)
}

export const format_currency = amt => {
  const num = parseInt(amt)
  const c = num.toLocaleString('en-US')
  return c === 'NaN' ? "" : c
}

export const format_ein = number => {
  const sep = "-", str = ""
  const first = number.slice(0, 2)
  const last = number.slice(2, 9)
  return str.concat(first, sep, last).slice(0, 10).replace(/[A-Za-z]/, "")
}

export const format_phone = phone => {
  let shift = 0, first = phone.slice(0, 1)
  const sep = "-", str = ""
  if (first !== '1') {
    first = '1'
    shift--
  }
  const cc = first + (phone.length > 1 ? sep : "")
  const ac = phone.slice(1 + shift, 4 + shift) + (phone.length > 4 ? sep : "")
  const a = phone.slice(4 + shift, 7 + shift) + (phone.length > 7 ? sep : "")
  const b = phone.slice(7 + shift, 11)
  return str.concat(cc, ac, a, b).slice(0, 14).replace(/[A-Za-z]/, "")
}

export const format_ssn = num => {
  const sep = "-", str = ""
  const a = num.slice(0, 3) + (num.length > 3 ? sep : "")
  const b = num.slice(3, 5) + (num.length > 5 ? sep : "")
  const c = num.slice(5, 11)
  return str.concat(a, b, c).slice(0, 11).replace(/[A-Za-z]/, "")
}

export const get_custom_field_from_cache = (key, id) => {
  const fields = JSON.parse(localStorage.getItem(key))
  return fields.find(field => (
    field.id === id
  ))
}

export const sanitize_special = str => {
  return str.replace(new RegExp(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/, 'g'), "")
}
