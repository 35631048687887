import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { format_append, format_currency, format_phone, format_ein, format_ssn, sanitize_special } from './util'

export const Checkbox = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <Field {...props} name={field.name} type="checkbox" checked={field.value.includes(props.value)} onChange={() => {
    if (field.value.includes(props.value)) {
      return setFieldValue(field.name, field.value.filter(value => {
        return value !== props.value
      }))
    }

    return setFieldValue(field.name, field.value.concat(props.value))
  }} />
)
export const CurrencyMask = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <input type="text" {...field} {...props} onKeyUp={e => {
    if (e.key === ',') return
    return setFieldValue(field.name, format_currency(sanitize_special(e.target.value)))
  }} placeholder="(USD)" />
)

export const EINMask = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <input type="text" {...field} {...props} onKeyUp={e => {
    if (e.key === '-' || e.key === 'Backspace') return
    return setFieldValue(field.name, format_ein(sanitize_special(e.target.value)))
  }} />
)
export const ErrorText = ({ children }) => (
  <span className="helper-text red-text">
    {children}
  </span>
)
export const ErrorSummary = ({ errors, touched, values, isSubmitting }) => (
  <div className="row">
    <div className="col s12">
      <h6 className={Object.keys(errors).length ? 'helper-text red-text' : 'helper-text'}>{Object.keys(errors).length ? "A required field is missing. Please review the errors below." : "*Marks Required Fields"}</h6>
    </div>
  </div>
)
export const MultiSelect = ({
  field,
  options,
  label,
  form: { setFieldValue, setFieldTouched },
  ...props
}) => (
  <select {...props} name={field.name} multiple={true}>
  	<option key="default" value="">{label === undefined ? "Choose all that apply..." : label}</option>
  	{options.map((item, key) => (
  		<option key={key} value={item}>{item}</option>
  	))}
  </select>
)
export const CustomFieldMultiSelect = ({
  field,
  options,
  label,
  form: { setFieldValue, setFieldTouched },
  ...props
}) => (
  <select {...props} name={field.name} multiple={true}>
    <option key="default" value="">{label === undefined ? "Choose all that apply..." : label}</option>
    {options.map((item, key) => (
      <option key={key} value={item.id}>{item.name}</option>
    ))}
  </select>
)
export const PhoneMask = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <input type="tel" {...field} {...props} onKeyUp={e => {
    if (e.key === '-' || e.key === 'Backspace') return
    if (e.target.value.length > 1) {
      return setFieldValue(field.name, format_phone(sanitize_special(e.target.value)))
    }
  }} placeholder="1-###-###-####" pattern="[0-9]{1}-[0-9]{3}-[0-9]{3}-[0-9]{4}" />
)
export const SSNMask = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <input type="text" {...field} {...props} onKeyUp={e => {
    if (e.key === '-' || e.key === 'Backspace') return
    if (e.target.value.length > 1) {
      return setFieldValue(field.name, format_ssn(sanitize_special(e.target.value)))
    }
  }} placeholder="###-##-####" pattern="[0-9]{3}-[0-9]{2}-[0-9]{4}" />
)
export const PercentMask = ({
  field,
  form: { values, touched, errors, setFieldValue },
  ...props
}) => (
  <input type="text" {...field} {...props} onKeyUp={e => {
    if (e.key === '%' || e.key === 'Backspace') return
    return setFieldValue(field.name, format_append(sanitize_special(e.target.value), "%", 3))
  }} placeholder="100%" pattern="[0-9]{1,3}%" />
)
export const RadioFieldGroup = ({
	field,
	options,
	label,
  small,
	form: { touched, errors },
}) => (
	<div className="input-field">
		<h6 className={touched[field.name] && errors[field.name] ? 'invalid' : ''}>{label}</h6>
    {small !== undefined ?  <small>{small}</small> : null}
		<ErrorMessage component={ErrorText} name={field.name} />
		{options.map(({value, label}, idx) => (
			<p key={idx}>
		    <label>
	        <Field name={field.name} className={field.name} type="radio" value={value} checked={value === field.value} />
	        <span>{label}</span>
	      </label>
	    </p>
		))}
  </div>
)

export const CheckboxFieldGroup = ({
  field,
  options,
  label,
  small,
  form: { touched, errors },
}) => (
  <div className="input-field">
    <h6 className={touched[field.name] && errors[field.name] ? 'invalid' : ''}>{label}</h6>
    {small !== undefined ?  <small>{small}</small> : null}
    <ErrorMessage component={ErrorText} name={field.name} />
    {options.map(({value, label}, idx) => (
      <p key={idx}>
        <label>
          <Field name={field.name} className={field.name} component={Checkbox} value={value} checked={value === field.value} />
          <span>{label}</span>
        </label>
      </p>
    ))}
  </div>
)